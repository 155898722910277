import { WagmiConfig, createClient, configureChains, mainnet, goerli } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import Header from './components/Header.js'
import Home from './components/Home.js';
import './App.css'
 
const { chains, provider, webSocketProvider } = configureChains(
  [mainnet, goerli],
  [publicProvider()],
)
 
const client = createClient({
  autoConnect: true,
  provider,
  webSocketProvider,
})

const dateTime = Date.now();
const timestamp = Math.floor(dateTime / 1000);
const isSiteLive = timestamp > 1675227601 // Feb 1, 2023

function App() {
  return (
  <>
    <WagmiConfig client={client}>
      <Header isSiteLive={isSiteLive} />
      <Home isSiteLive={isSiteLive} />
    </WagmiConfig>
  </>
  );
}

export default App;
