import {
  useContractRead,
  useWaitForTransaction,
  usePrepareContractWrite,
  useContractWrite,
} from "wagmi";
import { ethers, BigNumber } from "ethers";
import { useState, useEffect } from "react";
import whitelist from "../abi/whitelist.json";

export default function WhitelistMint({
  yoloAddress,
  yoloABI,
  address,
  isConnected,
  totalSupply,
}) {
  const [amount, setAmount] = useState(1);
  const isWhitelisted = whitelist.includes(address);
  const [hash, setHash] = useState("");
  const s1 = 475213;
  const s2 = "0xaF5CAf46530C56d3E286C53e659dc00A1A3d1B63";
  //console.log("whitelisted: " + isWhitelisted)

  // const yoloKidzContract = {
  //     address: yoloAddress,
  //     abi: yoloABI,
  // }

  useEffect(() => {
    if (isWhitelisted) {
      setHash(
        ethers.utils.solidityKeccak256(
          ["address", "uint256", "address"],
          [address, s1, s2]
        )
      );
    }
  }, [isWhitelisted]);
  //console.log(hash)

  const {
    data: dataSaleConfig,
    isError,
    isLoading,
  } = useContractRead({
    address: yoloAddress,
    abi: yoloABI,
    functionName: "saleConfig",
    enabled: isConnected,
  });
  //console.log(dataSaleConfig)

  const {
    data: dataMaxMintAmount,
    isErrorMaxMintAmount,
    isLoadingMaxMintAmount,
  } = useContractRead({
    address: yoloAddress,
    abi: yoloABI,
    functionName: "maxPerWhitelistMint",
    enabled: isConnected,
  });

  const hasWhitelistMinted = useContractRead({
    address: yoloAddress,
    abi: yoloABI,
    functionName: "whitelistMinted",
    args: [address],
  });
  //console.log(hasWhitelistMinted)
  {
    /** FIX THIS */
  }
  const { config, error } = usePrepareContractWrite({
    address: yoloAddress,
    abi: yoloABI,
    functionName: "whitelistMint",
    enabled:
      isConnected &&
      dataSaleConfig?.whitelistPrice &&
      amount &&
      hash &&
      !hasWhitelistMinted.data,
    args: [hash, String(amount)],
    overrides: {
      from: address,
      value: String(dataSaleConfig?.whitelistPrice * amount),
    },
  });
  const { data: dataWrite, write } = useContractWrite(config);

  const { isLoading: txLoading, isSuccess: txSuccess } = useWaitForTransaction({
    hash: dataWrite?.hash,
  });
  return (
    <>
      {isWhitelisted && !hasWhitelistMinted?.data && (
        <div className="mt-8">
          <div className="text-center">
            <h1 className="text-2xl">
              Price{" "}
              {String(dataSaleConfig.whitelistPrice / 1000000000000000000)}Ξ
            </h1>
          </div>
          <div className="border-lime-100 my-2 border-2 p-2 bg-gray-900">
            <p className="text-center text-xs">Minted</p>
            <p className="text-center text-xs">
              {String(3333 - totalSupply)} / 3333
            </p>
          </div>
          <input
            className="flex rounded-3xl mt-2 w-60 text-center m-auto text-black input-mint py-2 px-4"
            placeholder="Enter amount..."
            type="number"
            min="1"
            max={dataMaxMintAmount}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <div className="flex flex-col items-center">
            <button
              disabled={!write || txLoading}
              onClick={() => write?.()}
              className="cursor-pointer text-center items-center rounded-md bg-blue-500 hover:bg-blue-400 w-60 py-4 m-2"
            >
              {txLoading && <p>Minting...</p>}
              {!txLoading && txSuccess && <p>Minted!</p>}
              {!txLoading && !txSuccess && <p>Mint</p>}
            </button>
            <p className="text-xs italic">
              Max mint amount {String(dataMaxMintAmount)}!
            </p>
          </div>
        </div>
      )}
      {hasWhitelistMinted?.data && (
        <>
          <p className="text-sm italic text-center mt-20">Already minted!</p>
        </>
      )}
      {!isWhitelisted && (
        <>
          <p className="text-sm italic text-center mt-20">Not whitelisted</p>
        </>
      )}
    </>
  );
}
