import { useState, useEffect } from "react";
import {MobileNav,IconButton,} from "@material-tailwind/react";
import {useAccount,useConnect,useDisconnect,useEnsName,useContractRead, useNetwork} from "wagmi";
import { InjectedConnector } from 'wagmi/connectors/injected'
import truncateEthAddress from "truncate-eth-address";
import YoloLogoAnimated from '../images/yolo-animated-logo.gif'
import Twitter from '../images/twitter-tp.png'
import Discord from '../images/discord-tp.png'
import Opensea from '../images/opensea-tp.png'
import Looks from '../images/looks-tp.png'

export default function Header({isSiteLive}) {
  const [openNav, setOpenNav] = useState(false);
  const { address, isConnected } = useAccount()
  const { data: ensName } = useEnsName({ address })
  const { connect } = useConnect({connector: new InjectedConnector(),})
  const { disconnect } = useDisconnect()
  const { chain, chains } = useNetwork()
 
  return (
    <div className="w-screen drop-shadow-lg bg-green-grad">
      <div className="container mx-auto flex flex-row items-center justify-center text-white">
        {isSiteLive && <div className="basis-1/4"></div>}
        <div className="basis-1/2"><a href="/">
          <img className="w-40 mx-auto" src={YoloLogoAnimated} alt="Yolo Kidz" />
        </a></div>
        
        {!isConnected && isSiteLive && (
          <div className="basis-1/4"><button className="bg-orange-500 hover:bg-orange-400 px-4 py-2 ml-4 rounded-lg" onClick={connect}><p className="text-xs sm:text-sm">connect</p></button></div>
        )}
        {isConnected && isSiteLive && (
          <div className="basis-1/4"><button className="bg-orange-500 hover:bg-orange-400 px-4 py-2 rounded-lg" onClick={disconnect}>
            {ensName ? <p className="text-sm">{ensName}</p> : <p className="text-xs sm:text-sm">{truncateEthAddress(address)}</p>}
          </button></div>
        )}
      </div>
    </div>
  );
}
