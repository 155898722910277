import { useContractRead, useWaitForTransaction, usePrepareContractWrite, useContractWrite } from 'wagmi'
import {ethers} from 'ethers'
import { useState } from 'react';
import ThreeBarLoading from './ThreeBarLoading';
import EllipsisLoader from './EllipsisLoader';

export default function PublicMint({yoloAddress, yoloABI, address, isConnected, totalSupply}) {
    const [amount, setAmount] = useState(1);

    const { data: dataSaleConfig, isError, isLoading } = useContractRead({
        address: yoloAddress,
        abi: yoloABI,
        functionName: 'saleConfig',
        enabled: isConnected,
    })
    //console.log(dataSaleConfig)

    const { data: dataMaxMintAmount, isErrorMaxMintAmount, isLoadingMaxMintAmount } = useContractRead({
        address: yoloAddress,
        abi: yoloABI,
        functionName: 'maxPerTxPublic',
        enabled: isConnected,
    })
    
    const { config, error } = usePrepareContractWrite({
        address: yoloAddress,
        abi: yoloABI,
        functionName: 'publicSaleMint',
        enabled: isConnected && dataSaleConfig && amount,
        args: [amount],
        overrides: {
            from: address,
            value: String(dataSaleConfig?.publicPrice * amount),
        },
    })
    const { data: dataWrite, isLoading: isWaitingForUser, write } = useContractWrite(config)

    const {isLoading: txLoading, isSuccess: txSuccess} = useWaitForTransaction({
        hash: dataWrite?.hash,
      })
  return (
    <div className="mt-8">
    <div className="text-center">
        <h1 className="text-2xl mb-2">Price {String(dataSaleConfig?.publicPrice / 1000000000000000000)}Ξ</h1>
    </div>
    <div className="border-lime-100 border-2 p-2 my-2 bg-gray-900">
        <p className="text-center text-xs">Minted</p>
        <p className="text-center text-xs">{String(3333 - totalSupply)} / 3333</p>
    </div>
    <input
        className="flex rounded-3xl w-60 text-center m-auto text-black input-mint py-2 px-4"
        placeholder="Enter amount..."
        type="number"
        min="1"
        max={dataMaxMintAmount}
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
    />
    <div className="flex flex-col items-center">
        <button disabled={!write || txLoading || isWaitingForUser} onClick={() => write?.()} className="cursor-pointer text-center items-center rounded-md bg-green-500 hover:bg-green-400 w-60 py-4 m-2">
            {isWaitingForUser && 
                <div className="flex justify-center content-center">
                    <p className="text-base  mr-6">Awaiting</p>
                    <EllipsisLoader/>
                </div>}
            {txLoading && <div className="flex justify-center content-center">
                    <p className="text-base  mr-6">Minting</p>
                    <EllipsisLoader/>
                </div>}
            {!txLoading && txSuccess && <p>Minted!</p>}
            {!isWaitingForUser && !txLoading && !txSuccess && <p>Mint</p>}
        </button>
        <p className="text-xs italic">Max mint amount {String(dataMaxMintAmount)}!</p>
    </div>
    </div>
  )
}
