import React from 'react'
import { useContractRead, useWaitForTransaction, usePrepareContractWrite, useContractWrite } from 'wagmi'

export default function Claim({ yoloAddress, yoloABI, address, isConnected, freeClaims }) {
  const { data: dataHasClaims, isError, isLoading } = useContractRead({
    address: yoloAddress,
    abi: yoloABI,
    functionName: 'hasClaims',
    enabled: isConnected && address,
    args: [address],
  })

  const { config, error } = usePrepareContractWrite({
    address: yoloAddress,
    abi: yoloABI,
    functionName: 'claim',
    enabled: isConnected && dataHasClaims,
})
const { data: dataWrite, write, isLoading: isClaimLoading } = useContractWrite(config)

const {isLoading: txLoading, isSuccess: txSuccess} = useWaitForTransaction({
    hash: dataWrite?.hash,
  })
  return (<>
    {dataHasClaims && <div className="text-white text-center">
      <p className="text-base mt-4">You have a free claim available!</p>
      <button disabled={!write || txLoading || isClaimLoading} onClick={() => write?.()} className="bg-green-500 hover:bg-green-400 px-8 py-4 rounded-lg text-white my-4 mx-10">
        {isClaimLoading && <p>Awaiting...</p>}
        {txLoading && <p>Claiming...</p>}
        {!txLoading && txSuccess && <p>Claimed!</p>}
        {!txLoading && !txSuccess && !isClaimLoading && <p>Claim</p>}
      </button>
    </div>}</>
  )
}
