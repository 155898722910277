import { useState } from "react";
import Model from "./Model.js";
import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsName,
  useContractRead,
  useNetwork,
} from "wagmi";
import YoloBG from "../images/bg.gif";
import YoloPFP from "../images/yolo-demo.png";
import Switch from "@mui/material/Switch";
import PublicMint from "./PublicMint";
import yoloABI from "../abi/YoloKidz.json";
import freeClaims from "../abi/free-claims.json";
import Claim from "./Claim";
import WhitelistMint from "./WhitelistMint";
import Footer from "./Footer";
import ShootingStars from './ShootingStars';

const label = { inputProps: { "aria-label": "VX" } };

export default function Home({isSiteLive}) {
  const [amount, setAmount] = useState("");
  const { address, isConnected } = useAccount();
  const [checked, setChecked] = useState(false);
  const [publicOrWhitelist, setPublicOrWhitelist] = useState(false);
  const yoloAddress = "0xaE94E97d2a02f598B4602EAad33a93A591cF13e2";

  const {
    data: totalSupply,
    isErrorTotalSupply,
    isTotalSupply,
  } = useContractRead({
    address: yoloAddress,
    abi: yoloABI,
    functionName: "totalSupply",
    enabled: isConnected,
  });

  const handleChange = (event) => {
    setChecked(event.target.checked);
    //console.log(checked)
  };

  const handleMintChange = (event) => {
    setPublicOrWhitelist(event.target.checked);
    //console.log(publicOrWhitelist)
  };

  return (
    
      <div>
        <ShootingStars/>
        {isConnected && (
            <div className="grid grid-cols-1 md:grid-cols-2 place-content-center">
              <div className="mint-box mx-auto p-10 mt-60 sm:p-10 rounded-3xl border-lime-100 border-2">
                <div className="flex text-white items-baseline justify-center">
                  <p
                    className={`text-base ${
                      !publicOrWhitelist ? "text-green-400" : ""
                    }`}
                  >
                    Public
                  </p>
                  <Switch {...label} onChange={handleMintChange} />
                  <p
                    className={`text-base ${
                      publicOrWhitelist ? "text-blue-400" : ""
                    }`}
                  >
                    Whitelist
                  </p>
                </div>
                <div className=" text-white">
                  {!publicOrWhitelist ? (
                    <PublicMint
                      yoloAddress={yoloAddress}
                      yoloABI={yoloABI}
                      address={address}
                      isConnected={isConnected}
                      totalSupply={totalSupply}
                    />
                  ) : (
                    <WhitelistMint
                      yoloAddress={yoloAddress}
                      yoloABI={yoloABI}
                      address={address}
                      isConnected={isConnected}
                      totalSupply={totalSupply}
                    />
                  )}
                </div>
              </div>

              <div className="bg-mint mx-auto p-4 mt-2 sm:mt-60 rounded-3xl border-lime-100 border-2">
                <div className="flex text-white items-baseline m-auto justify-center">
                  <p className={`${!checked ? "text-yolo" : ""}`}>YOLO</p>
                  <Switch {...label} onChange={handleChange} />
                  <p className={`${checked ? "text-blue-400" : ""}`}>VX</p>
                </div>

                {!checked && (
                  <img
                    className="w-96 shrink-0 rounded-3xl"
                    src={YoloPFP}
                    alt="PFP Demo"
                  />
                )}
                {checked && <Model />}
              </div>
            </div>
        )}
        {!isConnected && isSiteLive &&(
          <div className="text-center mt-20">
            <p className="italic text-xl">Connect wallet to enter site</p>
          </div>
        )}
        {!isSiteLive &&
        <div>
          <h1 className="text-3xl animate-pulse text-yolo-light text-center mt-72">Coming soon</h1>
          <h1 className="text-3xl animate-pulse text-yolo-light text-center mt-96">A project by BR33D</h1>
        </div>
        }
        <Claim
          yoloAddress={yoloAddress}
          yoloABI={yoloABI}
          address={address}
          isConnected={isConnected}
          freeClaims={freeClaims}
        /><Footer />
      </div>
      
  );
}
