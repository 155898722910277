import { Suspense } from "react"
import { Canvas, useLoader } from "@react-three/fiber"
import { Environment, OrbitControls, PerspectiveCamera, useAnimations, useProgress  } from "@react-three/drei"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import Loader from './Loader.js'
import {useEffect} from 'react'
import { Progress } from "@material-tailwind/react";

function Model() {
  const { progress } = useProgress()
    const Model = () => {
        // location of the 3D model
        const gltf = useLoader(GLTFLoader, "./yolo-demo.gltf");
        console.log(gltf)
        const {actions} = useAnimations(gltf.animations, gltf.scene)
        console.log(actions)
        useEffect(() => {
          actions?.["Wave 01"]?.play();
        }, [])
        
        return (
          <>
            {/* Use scale to control the size of the 3D model */}
            <primitive object={gltf.scene} scale={0.15} position={[0,-3.5,0]}/>
          </>
        );
      };
      
  return (
    <div>
      <div className="three-model vx-bg object-contain rounded-2xl">
      <Canvas shadows dpr={[1, 2]}>
        <PerspectiveCamera makeDefault position={[4, 0, -10]}/>>
          <ambientLight intensity={0.2} />
          <spotLight intensity={0.2} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow />
          <Suspense fallback={<Loader/>}>
            <Model/>
            <Environment preset="city" />
          </Suspense>
          <OrbitControls />
        </Canvas>
      </div>
    </div>
  )
}

export default Model