import React from 'react'
import './ShootingStars.css'

export default function ShootingStars() {
  return (
    <>
    <section>
        <div className="stars"></div>
        <div className="stars"></div>
        <div className="stars"></div>
        <div className="stars"></div>
        <div className="stars"></div>
        <div className="stars"></div>
        <div className="stars"></div>
        <div className="stars"></div>
        <div className="stars"></div>
        <div className="stars"></div>
    </section>
    </>
  )
}
